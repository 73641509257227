import React, { useState } from "react";
import {
  Container,
  createGenerateClassName,
  createTheme,
  StylesProvider,
  ThemeProvider,
  Typography,
  Grid,
  withStyles,
  Box,
  Button,
} from "@material-ui/core";
import NavBar from "../../components/NavBar/NavBar";
import {
  DATE_FORMAT_DEFAULT,
  NAV_BARS,
  TIME_FORMAT,
  USER_ROLES,
} from "../../utils/constants";
import LessonCard from "../../components/LessonCard";
import EmptyLesson from "../../assets/images/empty-lesson.png";
import { formatInTimeZone, getDayOfWeek } from "../../utils/dateTime";
import UserCard from "../../components/UserCard";
import SharedSessionCard from "../../components/SharedSessionCard";
import SearchInput from "../../components/SearchInput/SearchInput";
import MainSlideImg1 from "../../assets/images/main-slide-img.png";
import MainSlideImg2 from "../../assets/images/main-slide-img-2.png";
import MainSlideImg1Mb from "../../assets/images/mb-main-slide-img.png";
import MainSlideImg2Mb from "../../assets/images/mb-main-slide-img-2.png";
import Slider from "react-slick";
import SignIn from "../../components/Modal/SignIn";
import { isMobile } from "../../utils/common";

const generateClassName = createGenerateClassName({
  productionPrefix: "c",
  disableGlobal: true,
  seed: "home",
});

const menuItemLinkStyle = {
  fontSize: 16,
  textTransform: "uppercase",
  color: "white",
  textDecoration: "none",
  fontWeight: 500,
};

const theme = createTheme({
  typography: {
    fontFamily: "'Varela Round', sans-serif",
  },
  overrides: {
    MuiFormLabel: {
      root: {
        "&$focused": {
          color: "#333333",
        },
      },

      focused: {
        color: "#333333",
      },
    },
    MuiInput: {
      underline: {
        "&::after": {
          borderBottom: "2px solid #333333",
        },
      },
    },
  },
});

const LessonListBox = withStyles(() => ({
  root: {
    borderTop: "1px solid #E0E0E0",
    flexGrow: 1,
    paddingBottom: 32,
  },
}))(Box);

const LessonEmptyBox = withStyles(() => ({
  root: {
    textAlign: "center",
    padding: "24px 0",
  },
}))(Box);

const NoData = withStyles(() => ({
  root: {
    fontSize: 16,
    lineHeight: "21px",
  },
}))(Typography);

const Title = withStyles(() => ({
  root: {
    fontSize: 30,
    margin: "24px 0",
    fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
    },
  },
}))(Typography);

const LessonListStatus = withStyles(() => ({
  root: {
    margin: "36px 0 24px 0",
    fontSize: 30,
    lineHeight: "40px",
    fontWeight: 400,
    color: "#000000",
    display: "flex",
    textTransform: "uppercase",
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
      margin: "20px 0 16px 0",
    },
  },
}))(Typography);

const UserList = withStyles(() => ({
  root: {
    flexWrap: "nowrap",
    overflowX: "auto",
  },
}))(Grid);

const UserItem = withStyles((theme) => ({
  root: {
    minWidth: "16.666667%",
    [theme.breakpoints.down("sm")]: {
      minWidth: "50%",
    },
  },
}))(Grid);

const ShowMoreBox = withStyles(() => ({
  root: {
    width: "100%",
    textAlign: "center",
    margin: "16px 0",
  },
}))(Box);

const ShowMoreButton = withStyles(() => ({
  root: {
    borderRadius: 142,
    border: "1px solid #000000",
    backgroundColor: "#ffffff",
    padding: "8px 12px",
    color: "#000000",
    "&:hover": { backgroundColor: "#000000", color: "#ffffff" },
  },
}))(Button);

const HomeSlider = withStyles(() => ({
  root: {
    "& .slick-dots": {
      bottom: 20,
      [theme.breakpoints.down("sm")]: {
        bottom: "47%",
      },
    },
    "& .slick-dots li button:before": {
      fontSize: 20,
      border: "1px solid #fff",
      borderRadius: "50%",
      [theme.breakpoints.down("sm")]: {
        fontSize: 14,
      },
    },
    "& .slick-dots li.slick-active button:before": {
      color: "#fff",
    },
  },
}))(Box);

const HomeSliderItem = withStyles((theme) => ({
  root: {
    position: "relative",
  },
}))(Box);

const HomeSliderItemContent = withStyles(() => ({
  root: {
    position: "absolute",
    top: "30%",
    left: "10%",
    color: "#fff",
    width: "40%",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      top: "auto",
      left: "auto",
      width: "auto",
      backgroundColor: "#000",
      padding: "40px 30px",
    },
  },
}))(Box);

const HomeSliderItemHead = withStyles(() => ({
  root: {
    fontWeight: 400,
    fontSize: 20,
    lineHeight: "26px",
    marginBottom: 8,
    textTransform: "uppercase",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      lineHeight: "16px",
    },
  },
}))(Typography);

const HomeSliderItemTitle = withStyles(() => ({
  root: {
    fontWeight: 400,
    fontSize: 46,
    textTransform: "uppercase",
    marginBottom: 32,
    [theme.breakpoints.down("sm")]: {
      fontSize: 28,
      lineHeight: "40x",
      marginBottom: 12,
    },
  },
}))(Typography);

const HomeSliderItemDes = withStyles(() => ({
  root: {
    fontWeight: 400,
    fontSize: 16,
    lineHeight: "26px",
    marginBottom: 32,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      lineHeight: "20x",
      marginBottom: 16,
    },
  },
}))(Typography);

const HomeSliderItemCTAWrapper = withStyles(() => ({
  root: {
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
  },
}))(Box);

const HomeSliderItemCTA = withStyles(() => ({
  root: {
    padding: "14px 24px",
    border: "1px solid #fff",
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    borderRadius: 62,
    textTransform: "uppercase",
    width: 200,
  },
}))(Box);

const HomeSliderItemImg = withStyles(() => ({
  root: {
    "& img": {
      width: "100%",
    },
  },
}))(Box);

const Home = () => {
  const current_user = window.current_user || {};
  const { id, first_name, last_name, role } = current_user;
  const upcoming_lessons = window.upcoming_lessons || {};
  const { data: upComingLessonList, included: upComingLessonIncluded } =
    upcoming_lessons;
  const shared_sessions = window.shared_sessions || {};
  const { data: sharedSessionList, included: sharedSessionIncluded } =
    shared_sessions;
  console.log('session list:', sharedSessionList)
  const recent_lessons = window.recent_lessons || {};
  const { data: recentLessonList, included: recentLessonIncluded } =
    recent_lessons;
  const myInstructorList = window.my_instructor || [];
  const myStudentList = window.my_student || [];

  const [upcomingLessonMaxShow, setUpcomingLessonMaxShow] = useState(6);
  const [recentLessonMaxShow, setRecentLessonMaxShow] = useState(6);
  const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [isShowSignInModal, setIsShowSignInModal] = useState(false);

  const amountLessonPerPage = 6;

  const findCreatorAvatar = (creatorId) => {
    const creator = sharedSessionIncluded.find(
      (includedItem) => includedItem.id === creatorId && includedItem.type === "user"
    );
    return creator ? creator.attributes.avatar_url : null;
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    <>
      <StylesProvider generateClassName={generateClassName}>
        <ThemeProvider theme={theme}>
          {id ? (
            <>
              <Container>
                <Title variant={"h2"}>Hi, {`${first_name}`}</Title>
                <NavBar active={NAV_BARS.HOME} />
                <LessonListBox>
                  <SearchInput />
                  {upComingLessonList.length === 0 &&
                    recentLessonList.length === 0 &&
                    Array.isArray(myInstructorList) &&
                    myInstructorList.length === 0 &&
                    Array.isArray(myStudentList) &&
                    myStudentList.length === 0 && (
                      <LessonEmptyBox>
                        <img src={EmptyLesson} alt="" />
                        <NoData>No data</NoData>
                      </LessonEmptyBox>
                    )}
                  {upComingLessonList.length > 0 && (
                    <>
                      <LessonListStatus>Upcoming Lesson</LessonListStatus>
                      <Grid container spacing={2}>
                        {upComingLessonList.map((item, index) => {
                          const { status, end_time, start_time } =
                            item.attributes;
                          const {
                            instructor: instructorRelated,
                            studio: studioRelated,
                            studio_address: studioAddressRelated,
                            student: studentRelated,
                          } = item.relationships;
                          const startDate = start_time
                            ? formatInTimeZone(
                                start_time,
                                DATE_FORMAT_DEFAULT,
                                clientTimeZone
                              )
                            : "";
                          const startHour = start_time
                            ? formatInTimeZone(
                                start_time,
                                TIME_FORMAT,
                                clientTimeZone
                              )
                            : "";
                          const endHour = end_time
                            ? formatInTimeZone(
                                end_time,
                                TIME_FORMAT,
                                clientTimeZone
                              )
                            : "";
                          const instructor = upComingLessonIncluded.find(
                            (e) =>
                              instructorRelated &&
                              instructorRelated.data &&
                              instructorRelated.data.id &&
                              e.id === instructorRelated.data.id
                          );
                          const student = upComingLessonIncluded.find(
                            (e) =>
                              studentRelated &&
                              studentRelated.data &&
                              studentRelated.data.id &&
                              e.id === studentRelated.data.id
                          );
                          const studio = upComingLessonIncluded.find(
                            (e) =>
                              studioRelated &&
                              studioRelated.data &&
                              studioRelated.data.id &&
                              e.id === studioRelated.data.id
                          );
                          const studioAddress = upComingLessonIncluded.find(
                            (e) =>
                              studioAddressRelated &&
                              studioAddressRelated.data &&
                              studioAddressRelated.data.id &&
                              e.id === studioAddressRelated.data.id
                          );
                          const user =
                            role === USER_ROLES.STUDENT
                              ? {
                                  role: "Instructor",
                                  full_name:
                                    instructor &&
                                    instructor.attributes &&
                                    instructor.attributes.first_name,
                                  avatar_url:
                                    instructor &&
                                    instructor.attributes &&
                                    instructor.attributes.avatar_url,
                                }
                              : {
                                  role: "Student",
                                  full_name:
                                    student &&
                                    student.attributes &&
                                    student.attributes.first_name,
                                  avatar_url:
                                    student &&
                                    student.attributes &&
                                    student.attributes.avatar_url,
                                };
                          const dayOfWeek = getDayOfWeek(start_time);

                          const studioAddressText =
                            studioAddress && studioAddress.attributes
                              ? `${studioAddress.attributes.street}, ${studioAddress.attributes.city}, ${studioAddress.attributes.country}`
                              : "";

                          if (index < upcomingLessonMaxShow) {
                            return (
                              <LessonCard
                                key={`lesson-card-${item.id}`}
                                id={item.id}
                                status={status}
                                studioName={
                                  studio && studio.attributes
                                    ? studio.attributes.name
                                    : ""
                                }
                                studioAddress={studioAddressText}
                                user={user}
                                startDate={startDate}
                                startHour={startHour}
                                endHour={endHour}
                                dayOfWeek={dayOfWeek}
                              />
                            );
                          }
                        })}
                      </Grid>
                      {upcomingLessonMaxShow < upComingLessonList.length && (
                        <ShowMoreBox>
                          <ShowMoreButton
                            onClick={() =>
                              setUpcomingLessonMaxShow(
                                upcomingLessonMaxShow + amountLessonPerPage
                              )
                            }
                          >
                            Show more
                          </ShowMoreButton>
                        </ShowMoreBox>
                      )}
                    </>
                  )}
                  {recentLessonList.length > 0 && (
                    <>
                      <LessonListStatus>Recent Lessons</LessonListStatus>
                      <Grid container spacing={2}>
                        {recentLessonList.map((item, index) => {
                          const {
                            name_of_student,
                            name_of_instructor,
                            status,
                            end_time,
                            start_time,
                          } = item.attributes;
                          const {
                            instructor: instructorRelated,
                            studio: studioRelated,
                            studio_address: studioAddressRelated,
                            student: studentRelated,
                          } = item.relationships;
                          const startDate = start_time
                            ? formatInTimeZone(
                                start_time,
                                DATE_FORMAT_DEFAULT,
                                clientTimeZone
                              )
                            : "";
                          const startHour = start_time
                            ? formatInTimeZone(
                                start_time,
                                TIME_FORMAT,
                                clientTimeZone
                              )
                            : "";
                          const endHour = end_time
                            ? formatInTimeZone(
                                end_time,
                                TIME_FORMAT,
                                clientTimeZone
                              )
                            : "";
                          const instructor = recentLessonIncluded.find(
                            (e) =>
                              instructorRelated &&
                              instructorRelated.data &&
                              instructorRelated.data.id &&
                              e.id === instructorRelated.data.id
                          );
                          const student = recentLessonIncluded.find(
                            (e) =>
                              studentRelated &&
                              studentRelated.data &&
                              studentRelated.data.id &&
                              e.id === studentRelated.data.id
                          );
                          const studio = recentLessonIncluded.find(
                            (e) =>
                              studioRelated &&
                              studioRelated.data &&
                              studioRelated.data.id &&
                              e.id === studioRelated.data.id
                          );
                          const studioAddress = recentLessonIncluded.find(
                            (e) =>
                              studioAddressRelated &&
                              studioAddressRelated.data &&
                              studioAddressRelated.data.id &&
                              e.id === studioAddressRelated.data.id
                          );
                          const user =
                            role === USER_ROLES.STUDENT
                              ? {
                                  role: "Instructor",
                                  full_name:
                                    instructor &&
                                    instructor.attributes &&
                                    instructor.attributes.first_name,
                                  avatar_url:
                                    instructor &&
                                    instructor.attributes &&
                                    instructor.attributes.avatar_url,
                                }
                              : {
                                  role: "Student",
                                  full_name:
                                    student &&
                                    student.attributes &&
                                    student.attributes.first_name,
                                  avatar_url:
                                    student &&
                                    student.attributes &&
                                    student.attributes.avatar_url,
                                };
                          const dayOfWeek = getDayOfWeek(start_time);

                          const studioAddressText =
                            studioAddress && studioAddress.attributes
                              ? `${studioAddress.attributes.street}, ${studioAddress.attributes.city}, ${studioAddress.attributes.country}`
                              : "";

                          if (index < recentLessonMaxShow) {
                            return (
                              <LessonCard
                                key={`lesson-card-${item.id}`}
                                name={
                                  role === USER_ROLES.STUDENT
                                    ? name_of_student
                                    : name_of_instructor
                                }
                                id={item.id}
                                status={status}
                                studioName={
                                  studio && studio.attributes
                                    ? studio.attributes.name
                                    : ""
                                }
                                studioAddress={studioAddressText}
                                user={user}
                                startDate={startDate}
                                startHour={startHour}
                                endHour={endHour}
                                dayOfWeek={dayOfWeek}
                              />
                            );
                          }
                        })}
                      </Grid>
                      {recentLessonMaxShow < recentLessonList.length && (
                        <ShowMoreBox>
                          <ShowMoreButton
                            onClick={() =>
                              setRecentLessonMaxShow(
                                recentLessonMaxShow + amountLessonPerPage
                              )
                            }
                          >
                            Show more
                          </ShowMoreButton>
                        </ShowMoreBox>
                      )}
                    </>
                  )}

                  {sharedSessionList.length > 0 && (
                    <>
                      <LessonListStatus>Shared Sessions</LessonListStatus>
                      <UserList container spacing={3}>
                        {sharedSessionList.map((session) => {
                          const { id, attributes: { title }, relationships: { creator } } = session;
                          const avatar_url = findCreatorAvatar(creator.data.id);
                          return (
                            <UserItem item xs={6} sm={6} md={2} key={`shared_session_${id}`}>
                              <SharedSessionCard
                                id={id}
                                title={title}
                                avatar_url={avatar_url}
                              />
                            </UserItem>
                          );
                        })}
                      </UserList>
                    </>
                  )}
                  
                  {role === USER_ROLES.STUDENT ? (
                    <>
                      {Array.isArray(myInstructorList) &&
                        myInstructorList.length > 0 && (
                          <>
                            <LessonListStatus>My instructors</LessonListStatus>
                            <UserList container spacing={3}>
                              {myInstructorList.map((instructor) => {
                                const { avatar_url, first_name } =
                                  instructor.attributes;
                                return (
                                  <UserItem
                                    item
                                    xs={6}
                                    sm={6}
                                    md={2}
                                    key={`instructor_${instructor.id}`}
                                  >
                                    <UserCard
                                      id={instructor.id}
                                      avatar_url={avatar_url}
                                      full_name={first_name}
                                    />
                                  </UserItem>
                                );
                              })}
                            </UserList>
                          </>
                        )}
                    </>
                  ) : (
                    <>
                      {Array.isArray(myStudentList) &&
                        myStudentList.length > 0 && (
                          <>
                            <LessonListStatus>My students</LessonListStatus>
                            <UserList container spacing={3}>
                              {myStudentList.map((student) => {
                                const { avatar_url, first_name } =
                                  student.attributes;
                                return (
                                  <UserItem
                                    item
                                    xs={6}
                                    sm={6}
                                    md={2}
                                    key={`student_${student.id}`}
                                  >
                                    <UserCard
                                      id={student.id}
                                      avatar_url={avatar_url}
                                      full_name={first_name}
                                    />
                                  </UserItem>
                                );
                              })}
                            </UserList>
                          </>
                        )}
                    </>
                  )}
                </LessonListBox>
              </Container>
            </>
          ) : (
            <HomeSlider>
              <Slider {...settings}>
                <HomeSliderItem>
                  <HomeSliderItemImg>
                    <img
                      src={isMobile() ? MainSlideImg2Mb : MainSlideImg2}
                      alt=""
                    />
                  </HomeSliderItemImg>

                  <HomeSliderItemContent>
                    <HomeSliderItemTitle>
                      Map Your Dance Journey
                    </HomeSliderItemTitle>
                    <HomeSliderItemDes>
                      Swyvel streamlines the in-studio experience for students,
                      instructors, and owners.
                    </HomeSliderItemDes>
                    <HomeSliderItemCTAWrapper>
                      <HomeSliderItemCTA>
                          <a 
                            onClick={() => setIsShowSignInModal(true)}
                            style={menuItemLinkStyle}
                          >
                            Sign in/Sign up
                          </a>
                      </HomeSliderItemCTA>
                    </HomeSliderItemCTAWrapper>
                  </HomeSliderItemContent>
                </HomeSliderItem>
                <HomeSliderItem>
                  <HomeSliderItemImg>
                    <img
                      src={isMobile() ? MainSlideImg1Mb : MainSlideImg1}
                      alt=""
                    />
                  </HomeSliderItemImg>

                  <HomeSliderItemContent>
                    <HomeSliderItemTitle>
                      Empower Your Dance Community
                    </HomeSliderItemTitle>
                    <HomeSliderItemDes>
                      Swyvel provides studios an end-to-end solution for
                      building their community.
                    </HomeSliderItemDes>
                    <HomeSliderItemCTAWrapper>
                      <HomeSliderItemCTA>
                        <a
                          onClick={() => setIsShowSignInModal(true)}
                          style={menuItemLinkStyle}
                        >
                          Sign in/Sign up
                        </a>
                      </HomeSliderItemCTA>
                    </HomeSliderItemCTAWrapper>
                  </HomeSliderItemContent>
                </HomeSliderItem>
              </Slider>
            </HomeSlider>
          )}
        </ThemeProvider>
      </StylesProvider>

      {isShowSignInModal && (
        <SignIn
          isOpen={isShowSignInModal}
          onClose={() => setIsShowSignInModal(false)}
        />
      )}
    </>
  );
};

export default Home;
