import React, { useState } from "react";
import {
  createGenerateClassName,
  StylesProvider,
  ThemeProvider,
  createTheme,
  withStyles,
  IconButton,
  Paper,
  InputBase,
  ListItem,
  List,
  Box,
  Typography,
  ListItemText,
  Popper,
  Fade,
  Link,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Cancel";
import BackIcon from "@material-ui/icons/ArrowBackIos";
import ArrowDownIcon from "@material-ui/icons/KeyboardArrowDownOutlined";
import ArrowUpIcon from "@material-ui/icons/KeyboardArrowUpOutlined";
import filterIcon from "../../assets/images/filter.svg";
import { updateQueryStringParameter } from "../../utils/common";

const generateClassName = createGenerateClassName({
  productionPrefix: "c",
  disableGlobal: true,
  seed: "search-input",
});

const theme = createTheme({
  typography: {
    fontFamily: "'Varela Round', sans-serif",
  },
  overrides: {
    MuiFormLabel: {
      root: {
        "&$focused": {
          color: "#333333",
        },
      },

      focused: {
        color: "#333333",
      },
    },
    MuiInput: {
      underline: {
        "&::after": {
          borderBottom: "2px solid #333333",
        },
      },
    },
  },
});

const SearchInputBox = withStyles((theme) => ({
  root: {
    width: 370,
    borderRadius: 142,
    boxShadow: "none",
    border: "1px solid #000000",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}))(Paper);

const InputInstance = withStyles((theme) => ({
  root: {
    width: "calc(100% - 96px)",
  },
}))(InputBase);

const ActionBox = withStyles((theme) => ({
  root: {
    display: "flex",
    padding: "24px 0",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      padding: 0,
      paddingTop: 24,
    },
  },
}))(Box);

const ListBox = withStyles((theme) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: 16,
    },
  },
}))(Box);

const ListBoxSearch = withStyles((theme) => ({
  root: {
    display: "flex",
    marginLeft: "auto",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },
}))(Box);

const BackButton = withStyles((theme) => ({
  root: {
    boxShadow: "none",
    width: 120,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
  },
}))(Paper);

const BackText = withStyles((theme) => ({
  root: {
    fontSize: 30,
    fontWeight: "normal",
    lineHeight: "41px",
    textTransform: "uppercase",
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
      lineHeight: "28px",
    },
  },
}))(Typography);

const FilterButton = withStyles((theme) => ({
  root: {
    width: 175,
    borderRadius: 142,
    boxShadow: "none",
    border: "1px solid #000000",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    marginLeft: 15,
    cursor: "pointer",
    "& img": {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  },
}))(Paper);

const FilterText = withStyles(() => ({
  root: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "22px",
    marginLeft: 12,
  },
}))(Typography);

const FilterPopperWrapper = withStyles(() => ({
  root: {
    width: 175,
    backgroundColor: "#ffffff",
    borderRadius: 16,
    border: "1px solid #E0E0E0",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.05)",
    position: "relative",
  },
}))(Box);

const FilterList = withStyles(() => ({
  root: {
    padding: 0,
    "& li a": {
      display: "block",
      width: "100%",
    },
  },
}))(List);

const FilterListItemText = withStyles(() => ({
  root: { color: "#000000" },
}))(ListItemText);

const SearchInput = () => {
  const { search, pathname, href } = window.location;
  const searchParam = new URLSearchParams(search);
  const rangeParam = searchParam.get("range") || "week";
  const queryParam = searchParam.get("q");
  const [searchKey, setSearchKey] = useState(queryParam || "");
  const [myFilterPopperEl, setMyFilterPopperEl] = useState(null);
  const [openMyFilterPopper, setOpenMyFilterPopper] = useState(false);
  const current_user = window.current_user || {};
  const { manage_studio_id } = current_user;

  const onSearch = () => {
    const url = updateQueryStringParameter(
      href,
      "q",
      encodeURIComponent(searchKey)
    );
    window.location.href = url;
  };

  const keyPress = (e) => {
    if (e.keyCode == 13) {
      onSearch();
    }
  };

  const onSearchChange = (e) => {
    e.preventDefault();
    setSearchKey(e.target.value);
  };

  const onOpenFilterPopper = (event) => {
    setMyFilterPopperEl(event.currentTarget);
    setOpenMyFilterPopper(!openMyFilterPopper);
  };

  const onClearQueryParam = () => {
    if (queryParam && queryParam !== "") {
      setSearchKey("");
      const url = updateQueryStringParameter(href, "q", "");
      window.location.href = url;
    } else {
      setSearchKey("");
    }
  };

  return (
    <StylesProvider generateClassName={generateClassName}>
      <ThemeProvider theme={theme}>
        <ActionBox>
          <ListBoxSearch>
            <SearchInputBox>
              <IconButton
                onClick={onSearch}
                sx={{ p: "10px" }}
                aria-label="search"
              >
                <SearchIcon />
              </IconButton>
              <InputInstance
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search"
                inputProps={{ "aria-label": "search" }}
                onChange={onSearchChange}
                value={searchKey}
                onKeyDown={keyPress}
              />
              {searchKey && (
                <IconButton
                  onClick={onClearQueryParam}
                  sx={{ p: "10px" }}
                  aria-label="search"
                >
                  <CloseIcon />
                </IconButton>
              )}
            </SearchInputBox>
            {pathname.includes("overview") && (
              <FilterButton onClick={onOpenFilterPopper}>
                <Box style={{ display: "flex" }}>
                  <img src={filterIcon} alt="" width={18} />
                  <FilterText>
                    {rangeParam === "half" && "6 months"}
                    {rangeParam === "week" && "Week"}
                    {rangeParam === "month" && "Month"}
                    {rangeParam === "year" && "Year"}
                  </FilterText>
                </Box>
                {!openMyFilterPopper ? <ArrowDownIcon /> : <ArrowUpIcon />}
              </FilterButton>
            )}
          </ListBoxSearch>
        </ActionBox>

        {openMyFilterPopper && (
          <Popper
            open={openMyFilterPopper}
            anchorEl={myFilterPopperEl}
            placement={"bottom"}
            transition
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <FilterPopperWrapper>
                  <FilterList>
                    <ListItem>
                      <Link
                        href={`/studios/${manage_studio_id}/my_business/overview?range=week`}
                      >
                        <FilterListItemText primary="Week" />
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link
                        href={`/studios/${manage_studio_id}/my_business/overview?range=month`}
                      >
                        <FilterListItemText primary="Month" />
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link
                        href={`/studios/${manage_studio_id}/my_business/overview?range=half`}
                      >
                        <FilterListItemText primary="6 months" />
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link
                        href={`/studios/${manage_studio_id}/my_business/overview?range=year`}
                      >
                        <FilterListItemText primary="Year" />
                      </Link>
                    </ListItem>
                  </FilterList>
                </FilterPopperWrapper>
              </Fade>
            )}
          </Popper>
        )}
      </ThemeProvider>
    </StylesProvider>
  );
};

export default SearchInput;
