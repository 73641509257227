import {
  Box,
  Grid,
  makeStyles,
  Paper,
  Typography,
  withStyles,
  Tooltip,
  CardMedia,
} from "@material-ui/core";
import React from "react";
import UserDefaultImage from "../../assets/images/user-default.png";
import AccessTimeOutlinedIcon from "@material-ui/icons/AccessTimeOutlined";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import { bool, object, string } from "prop-types";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import EditLessonNoteIcon from "../../assets/images/edit-lesson-note-icon.svg";
import AddLessonNoteIcon from "../../assets/images/add-lesson-note-icon.svg";
import { isBrowser  } from "../../utils/common";

const useStyles = makeStyles((theme) => ({
  lessonImage: {
    maxWidth: "130px",
    width: "100%",
    borderTopLeftRadius: 16,
    height: "100%",
  },
  lessonName: {
    fontSize: 18,
    lineHeight: "24px",
    fontWeight: 500,
    marginBottom: 8,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  userFullName: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "20px",
  },
  lessonNoteIcon: {
    width: 23,
    [theme.breakpoints.down("sm")]: {
      width: 20,
    },
  },
}));

const ClockIcon = withStyles(() => ({
  root: {
    fontSize: 15,
    marginRight: 8,
  },
}))(AccessTimeOutlinedIcon);

const HomeIcon = withStyles(() => ({
  root: {
    fontSize: 15,
    marginRight: 8,
  },
}))(HomeOutlinedIcon);

const UserIcon = withStyles(() => ({
  root: {
    fontSize: 15,
    marginRight: 8,
  },
}))(PersonOutlineIcon);

const LessonWrapper = withStyles(() => ({
  root: {
    borderRadius: 16,
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.05)",
    display: "flex",
    border: ({ isproposedtime }) =>
      isproposedtime === "true" ? "1px dashed #000000" : "1px solid #E0E0E0",
    width: "100%",
    overflow: "hidden",
  },
}))(Paper);

const LessonImage = withStyles((theme) => ({
  root: {
    position: "relative",
    minWidth: "130px",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}))(Box);

const LessonInfoWrapper = withStyles((theme) => ({
  root: {
    padding: "12px 12px 16px 12px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    overflow: "hidden",
  },
}))(Box);

const LessonInfo = withStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: ({ hasname }) => (hasname === "true" ? 0 : 20),
    [theme.breakpoints.down("sm")]: {
      marginTop: ({ hasname }) => (hasname === "true" ? 0 : 0),
      width: "100%",
    },
  },
}))(Box);

const UserInfoMb = withStyles((theme) => ({
  root: {
    display: "none",
    paddingBottom: 12,
    paddingTop: ({ hasname }) => (hasname === "true" ? 12 : 0),
    borderTop: ({ hasname }) => (hasname === "true" ? "1px solid #E0E0E0" : 0),
    alignItems: "center",
    borderBottom: "1px solid #E0E0E0",
    marginTop: ({ hasname }) => (hasname === "true" ? 8 : 0),
    marginBottom: 8,
    [theme.breakpoints.down("sm")]: {
      display: "flex",
    },
    "& > a": {
      display: "flex",
      textDecoration: "none",
      color: "#333333",
      alignItems: "center",
    },
  },
}))(Box);

const LessonInfoHead = withStyles(() => ({
  root: {
    width: "73%",
  },
}))(Box);

const UserRoleMb = withStyles(() => ({
  root: {
    fontSize: 12,
    lineHeight: "16px",
    margin: 0,
  },
}))(Typography);

const UserFullNameMb = withStyles(() => ({
  root: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "21px",
    margin: 0,
  },
}))(Typography);

const Info = withStyles(() => ({
  root: {
    margin: 0,
    fontSize: 14,
    lineHeight: "18px",
    marginBottom: 8,
    display: "flex",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
}))(Typography);

const InfoUser = withStyles((theme) => ({
  root: {
    margin: 0,
    fontSize: 14,
    lineHeight: "18px",
    marginBottom: 8,
    display: "flex",
    fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}))(Typography);

const AddLessonNoteBox = withStyles((theme) => ({
  root: {
    position: "absolute",
    display: "flex",
    top: 12,
    right: 16,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      top: 8,
      right: 8,
    },
  },
}))(Box);

const LessonNoteText = withStyles((theme) => ({
  root: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: "18px",
    margin: 0,
    color: "#007BFF",
  },
}))(Typography);

const CardMediaImage = withStyles(() => ({
  root: {
    width: "100%",
    height: 0,
    paddingTop: "100%",
    backgroundSize: "cover",
  },
}))(CardMedia);

const LessonCard = ({
  name,
  studioName,
  startDate,
  startHour,
  endHour,
  id,
  user,
  currentUser,
  otherUser,
  isProposedTime,
  onClickProposedTimeHandler,
  dayOfWeek,
  isCompleted,
  openCreateLessonNoteModal,
  isShowAddLessonNote,
}) => {
  const classes = useStyles();
  console.log(typeof(currentUser));
  //^I put this in for debugging purposes... for some reason there are times in development server where this would show up as undefined... not sure if has to do with a full reload of the page vs a partial one... please help me understand why the variable doesn't get captured sometime in Lessons -> Scheduling
  return (
    <Grid item xs={12} sm={6} md={4}>
      <LessonWrapper
        isproposedtime={Boolean(!!isProposedTime).toString()}
        onClick={
          Boolean(!!isProposedTime) ? onClickProposedTimeHandler : undefined
        }
      >
        <LessonImage>
          {!isProposedTime ? (
            <a href={`/lessons/${id}`}>
              <CardMediaImage image={user.avatar_url || UserDefaultImage} />
              <img
                src={user.avatar_url || UserDefaultImage}
                alt=""
                className={classes.lessonImage}
                style={{ display: "none" }}
              />
            </a>
          ) : (
            <>
              <CardMediaImage image={user.avatar_url || UserDefaultImage} />
              <img
                src={user.avatar_url || UserDefaultImage}
                alt=""
                className={classes.lessonImage}
                style={{ borderBottomLeftRadius: 16, display: "none" }}
              />
            </>
          )}
        </LessonImage>
        <LessonInfoWrapper
          style={{
            cursor: isProposedTime ? "pointer" : "initial",
          }}
        >
          {name && (
            <LessonInfoHead>
              <Box>
                <a
                  href={`/lessons/${id}`}
                  style={{ textDecoration: "none", color: "#000000" }}
                >
                  <Tooltip
                    title={<Typography>{name}</Typography>}
                    arrow
                    placement={"top"}
                  >
                    <Typography className={classes.lessonName}>
                      {name} 
                    </Typography>
                  </Tooltip>
                </a>
              </Box>
            </LessonInfoHead>
          )}
          <LessonInfo hasname={Boolean(name).toString()}>
            {user && (
              <UserInfoMb hasname={Boolean(name).toString()}>
                {!isProposedTime ? (
                  <a href={`/lessons/${id}`}>
                    <img
                      src={user.avatar_url || UserDefaultImage}
                      alt=""
                      style={{
                        width: 40,
                        height: 40,
                        borderRadius: "50%",
                        marginRight: 16,
                      }}
                    />
                    <Box>
                      <UserRoleMb>{user.role}</UserRoleMb>
                      <UserFullNameMb>{user.full_name}</UserFullNameMb>
                    </Box>
                  </a>
                ) : (
                  <>
                    <img
                      src={user.avatar_url || UserDefaultImage}
                      alt=""
                      style={{
                        width: 40,
                        height: 40,
                        borderRadius: "50%",
                        marginRight: 16,
                      }}
                    />
                    <Box>
                      <UserRoleMb>{user.role}</UserRoleMb>
                      <UserFullNameMb>{user.full_name}</UserFullNameMb>
                    </Box>
                  </>
                )}
              </UserInfoMb>
            )}
            {user.full_name && (
              <InfoUser>
                <UserIcon /> {user.full_name}
                {(currentUser !== {} && currentUser !== undefined && otherUser !== {} && otherUser !== undefined) && ( 
                  (currentUser.role === 'owner' && otherUser.full_name) &&      
                   ` with ${otherUser.full_name}`   
                )}
              </InfoUser>
            )}
            {studioName && (
              <Info>
                <HomeIcon /> {studioName}
              </Info>
            )}
            {dayOfWeek && startDate && startHour && endHour && (
              <Info style={{ fontWeight: 500, fontSize: isBrowser() ? 11 : 14 }}>
                <ClockIcon />{" "}
                <span
                  style={{
                    color: "#007BFF",
                  }}
                >
                  {dayOfWeek} {startDate}
                </span>
                &nbsp;|&nbsp;
                <span
                  style={{
                    color: "#007BFF",
                  }}
                >
                  {startHour} - {endHour}
                </span>
              </Info>
            )}
          </LessonInfo>
          {isCompleted && (
            <AddLessonNoteBox onClick={openCreateLessonNoteModal}>
              <img
                src={
                  !isShowAddLessonNote ? EditLessonNoteIcon : AddLessonNoteIcon
                }
                alt=""
                className={classes.lessonNoteIcon}
              />
              <LessonNoteText>Lesson note</LessonNoteText>
            </AddLessonNoteBox>
          )}
        </LessonInfoWrapper>
      </LessonWrapper>
    </Grid>
  );
};

LessonCard.propTypes = {
  name: string,
  studioName: string,
  studioAddress: string,
  startDate: string,
  startHour: string,
  endHour: string,
  status: string,
  id: string,
  user: object,
  currentUser: object,
  otherUser: object,
  isProposedTime: bool,
  detailsId: string,
  durationText: string,
  recurring: bool,
  isCompleted: bool,
};

LessonCard.defaultPropTypes = {
  name: null,
  studioName: null,
  studioAddress: null,
  startDate: null,
  startHour: null,
  endHour: null,
  status: null,
  id: null,
  user: {},
  currentUser: {},
  otherUser: {},
  isProposedTime: false,
  detailsId: null,
  durationText: null,
  recurring: false,
  isCompleted: false,
};

export default LessonCard;
