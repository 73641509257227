import React, { useState } from "react";
import {
  Box,
  withStyles,
  Typography,
  createGenerateClassName,
  StylesProvider,
  createTheme,
  ThemeProvider,
  Button,
  Backdrop,
  Fade,
  Modal,
  Popper,
  List,
  ListItem,
  ListItemText,
  FormControl,
  IconButton,
  TextField,
  Menu,
  ClickAwayListener,
  InputLabel,
  MenuItem,
  Select,
  CircularProgress,
} from "@material-ui/core";
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {
  LESSON_DATE_TIME,
  PROPOSAL_TIME_OPTION_TYPES,
} from "../../../utils/constants";
import CachedIcon from "@material-ui/icons/Cached";
import signUpSuccessImg from "../../../assets/images/sign-up-success.svg";
import UserDefaultImage from "../../../assets/images/user-default.png";
import lessonsApi from "../../../apis/lessonsApi";
import { format, isValid } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";

const generateClassName = createGenerateClassName({
  productionPrefix: "c",
  disableGlobal: true,
  seed: "create-lesson-modal",
});

const theme = createTheme({
  typography: {
    fontFamily: "'Varela Round', sans-serif",
  },
  overrides: {
    MuiFormLabel: {
      root: {
        "&$focused": {
          color: "#333333",
        },
      },

      focused: {
        color: "#333333",
      },
    },
    MuiInput: {
      underline: {
        "&::after": {
          borderBottom: "2px solid #333333",
        },
      },
    },
  },
});

const SubmitProposalTimeButton = withStyles(() => ({
  root: {
    color: "#ffffff",
    backgroundColor: "#000000",
    "&:hover": {
      backgroundColor: "#000000",
    },
    height: 50,
    borderRadius: "140px",
    width: "100%",
  },
}))(Button);

const ProposeModal = withStyles((theme) => ({
  root: {
    backgroundColor: "#ffffff",
    padding: 32,
    borderRadius: 16,
    position: "relative",
    maxWidth: 500,
    maxHeight: "85%",
    margin: "auto",
    outline: 0,
    overflow: "auto",
    width: 500,
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      padding: 16,
      maxWidth: "100%",
      width: "100%",
      maxHeight: "95%",
      marginTop: "auto",
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
}))(Box);

const Close = withStyles(() => ({
  root: {
    position: "absolute",
    right: 12,
    top: 12,
    cursor: "pointer",
  },
}))(Box);

const ProposeInstructorInfo = withStyles(() => ({
  root: {
    fontSize: 18,
    lineHeight: "25px",
    fontWeight: 400,
    display: "flex",
    padding: "16px 0",
    alignItems: "center",
    "& > img": {
      width: 60,
      height: 60,
      borderRadius: "50%",
      marginRight: 16,
    },
  },
}))(Box);

const ProposeInstructorNameBox = withStyles(() => ({
  root: {},
}))(Box);

const ProposeUserRole = withStyles(() => ({
  root: {
    fontSize: 16,
    lineHeight: "21px",
    margin: 0,
  },
}))(Typography);

const ProposeInstructorName = withStyles(() => ({
  root: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: "28px",
    margin: 0,
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
}))(Typography);

const ProposeModalInstructorList = withStyles(() => ({
  root: {
    backgroundColor: "#ffffff",
    border: "1px solid #E0E0E0",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.05)",
    borderRadius: 16,
    maxHeight: 300,
    overflowY: "auto",
    minWidth: 200,
  },
}))(Box);

const ProposalTimesPrimary = withStyles(() => ({
  root: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "20px",
    color: "#000000",
  },
}))(Typography);

const ProposalTimesBox = withStyles(() => ({
  root: {},
}))(Box);

const ProposalTime = withStyles((theme) => ({
  root: {
    padding: "12px 0",
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
}))(Box);

const ProposalTimeOrder = withStyles(() => ({
  root: {
    marginRight: 12,
    fontSize: 12,
    color: "#ffffff",
    backgroundColor: ({ active }) =>
      active === "true" ? "#00CC5E" : "#000000",
    width: 20,
    height: 20,
    "&:hover": {
      backgroundColor: ({ active }) =>
        active === "true" ? "#00CC5E" : "#000000",
    },
  },
}))(IconButton);

const ProposalTimeDatePicker = withStyles((theme) => ({
  root: {
    position: "relative",
    width: "50%",
    maxWidth: "50%",
    marginRight: 12,
    [theme.breakpoints.down("sm")]: {
      width: ({ hasThreeOptions }) =>
        hasThreeOptions === "true" ? "50%" : "60%",
      maxWidth: ({ hasThreeOptions }) =>
        hasThreeOptions === "true" ? "50%" : "60%",
      marginRight: 0,
      "& input": {
        fontSize: ({ hasThreeOptions }) =>
          hasThreeOptions === "true" ? 12 : 14,
      },
      "& button": {
        paddingLeft: ({ hasThreeOptions }) =>
          hasThreeOptions === "true" ? "0" : 12,
      },
    },
  },
}))(Box);

const ProposalTimeCurrentOption = withStyles((theme) => ({
  root: {
    marginLeft: 12,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 8,
    },
  },
}))(Box);

const ProposalTimeOptions = withStyles(() => ({
  root: {
    marginLeft: "auto",
  },
}))(Box);

const ProposalTimeOptionsBox = withStyles(() => ({
  root: {
    display: "flex",
    padding: "8px 12px",
    backgroundColor: "#FFFFFF",
    position: "relative",
  },
}))(Box);

const ProposalTimeOptionsBoxTriangle = withStyles(() => ({
  root: {
    position: "absolute",
    bottom: "-7px",
    left: "auto",
    right: "7%",
    width: 12,
    height: 12,
    margin: "auto",
    transform: "rotate(45deg)",
    "-webkit-transform": "rotate(45deg)",
    "-moz-transform": "rotate(45deg)",
    "-o-transform": "rotate(45deg)",
    "-ms-transform": "rotate(45deg)",
    borderRight: "1px solid #E0E0E0",
    borderBottom: "1px solid #E0E0E0",
    backgroundColor: "#ffffff",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.05)",
  },
}))(Box);

const ProposalTimeOption = withStyles(() => ({
  root: {
    fontSize: 11,
    fontWeight: 600,
    lineHeight: "15px",
  },
}))(Box);

const ProposalTimeOptionItem = withStyles(() => ({
  root: {
    padding: "0 12px",
  },
}))(Box);

const ProposalTimeOptionItemRecurring = withStyles(() => ({
  root: {
    padding: "0 12px",
    position: "relative",
    "&::after": {
      content: '""',
      position: "absolute",
      top: 0,
      right: 0,
      borderLeft: "1px solid #E0E0E0",
      width: 1,
      height: "100%",
    },
  },
}))(Box);

const ProposalTimeOptionIconButton = withStyles(() => ({
  root: {
    border: ({ active }) =>
      active === "true" ? "1px solid #007BFF" : "1px solid #000000",
    borderRadius: "50%",
    width: 24,
    height: 24,
    color: ({ active }) => (active === "true" ? "#ffffff" : "#000000"),
    backgroundColor: ({ active }) =>
      active === "true" ? "#007BFF" : "#ffffff",
    "&:hover": {
      border: "1px solid #007BFF",
      backgroundColor: "#007BFF",
      color: "#ffffff",
    },
  },
}))(IconButton);

const ProposalTimeCurrentOptionIconButton = withStyles(() => ({
  root: {
    border: "1px solid #007BFF",
    borderRadius: "50%",
    width: 24,
    height: 24,
    color: "#000000",
    backgroundColor: "#007BFF",
    color: "#ffffff",
    "&:hover": {
      border: "1px solid #007BFF",
      backgroundColor: "#007BFF",
      color: "#ffffff",
    },
  },
}))(IconButton);

const ImageSignUpSuccess = withStyles(() => ({
  root: {
    textAlign: "center",
    marginBottom: 30,
    minHeight: 160,
  },
}))(Box);

const TitleModal = withStyles(() => ({
  root: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: "28px",
    textTransform: "uppercase",
  },
}))(Typography);

const DatePickerTextField = withStyles((theme) => ({
  root: {
    maxWidth: "100%",
  },
}))(TextField);

const MoreIconButton = withStyles((theme) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      padding: 4,
    },
  },
}))(IconButton);

const ProposalTimeOptionsMenu = withStyles((theme) => ({
  paper: {
    border: "1px solid #E0E0E0",
    borderRadius: 16,
  },
}))(Menu);

const NotificationPopperTriangle = withStyles((theme) => ({
  root: {
    position: "absolute",
    top: "-7px",
    left: "50%",
    width: 12,
    height: 12,
    margin: "auto",
    transform: "translateX(-6px) rotate(45deg)",
    "-webkit-transform": "translateX(-6px) rotate(225deg)",
    "-moz-transform": "translateX(-6px) rotate(45deg)",
    "-o-transform": "translateX(-6px) rotate(45deg)",
    "-ms-transform": "translateX(-6px) rotate(45deg)",
    borderRight: "1px solid #E0E0E0",
    borderBottom: "1px solid #E0E0E0",
    backgroundColor: "#ffffff",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.05)",
    [theme.breakpoints.down("sm")]: {
      left: "59%",
    },
  },
}))(Box);

const ProposeInstructorInfoImg = withStyles((theme) => ({
  root: {
    height: 60,
    width: 60,
    backgroundImage: ({ backgroundImage }) => `url(${backgroundImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "center",
    position: "relative",
    boxShadow: "none",
    borderRadius: "50%",
    marginRight: 16,
  },
}))(Box);

const AddLessonModal = ({
  isOpen,
  onClose,
  instructors,
  students,
  studio_id,
  date,
  isReload,
  addresses = [],
  studioName = "",
}) => {
  const current_user = window.current_user || {};
  const { id } = current_user;
  const [isCreateLessonSuccessfully, setIsCreateLessonSuccessFully] =
    useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [currentStudent, setCurrentStudent] = useState({});
  const [currentInstructor, setCurrentInstructor] = useState(
    Array.isArray(instructors) && instructors.length > 0 ? instructors[0] : {}
  );
  const [isOpenStudentListPopper, setIsOpenStudentListPopper] = useState(false);
  const [studentListPopperEl, setStudentListPopperEl] = useState(null);
  const [isOpenInstructorListPopper, setIsOpenInstructorListPopper] =
    useState(false);
  const [instructorListPopperEl, setInstructorListPopperEl] = useState(null);
  const [proposalTimesOut, setProposalTimesOut] = useState([
    {
      id: 1,
      date_time: date || null,
      options: ["1x"],
    },
  ]);
  const [isOpenProposalTimeOptionsMenu, setIsOpenProposalTimeOptionsMenu] =
    useState(false);
  const [currentProposalTime, setCurrentProposalTime] = useState();
  const [proposalTimeOptionsMenuEl, setProposalTimeOptionsMenuEl] =
    useState(null);
  const [addressId, setAddressId] = useState(
    Array.isArray(addresses) && addresses.length > 0 ? addresses[0].id : ""
  );
  const [datePickerErrorMessage, setDatePickerErrorMessage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const onOpenStudentListPopper = (event) => {
    setStudentListPopperEl(event.currentTarget);
    setIsOpenStudentListPopper(!isOpenStudentListPopper);
  };

  const onCloseStudentListPopper = (event) => {
    setStudentListPopperEl(null);
    setIsOpenStudentListPopper(false);
  };

  const onOpenInstructorListPopper = (event) => {
    setInstructorListPopperEl(event.currentTarget);
    setIsOpenInstructorListPopper(!isOpenInstructorListPopper);
  };

  const onCloseInstructorListPopper = (event) => {
    setInstructorListPopperEl(null);
    setIsOpenInstructorListPopper(false);
  };

  const onClickStudentPopper = (stu) => {
    setErrorMessage();
    setStudentListPopperEl(null);
    setIsOpenStudentListPopper(!isOpenStudentListPopper);
    if (currentStudent.id !== stu.id) {
      setCurrentStudent(stu);

      const { last_studio_address_id } = stu.attributes;
      if (last_studio_address_id) {
        setAddressId(last_studio_address_id);
      }
    }
  };

  const onClickInstructorPopper = (ins) => {
    setErrorMessage();
    setInstructorListPopperEl(null);
    setIsOpenInstructorListPopper(!isOpenInstructorListPopper);
    if (currentInstructor.id !== ins.id) {
      setCurrentInstructor(ins);
    }
  };

  const onChangeProposalDateTime = (event, id) => {
    setProposalTimesOut(
      proposalTimesOut.map((item) => {
        if (item.id === id) {
          return {
            id: item.id,
            date_time: event,
            options: item.options,
          };
        }
        return item;
      })
    );
  };

  const onOpenProposalTimeOptionsPopper = (event, proposalTime) => {
    setCurrentProposalTime(proposalTime);
    setProposalTimeOptionsMenuEl(event.currentTarget);
    setIsOpenProposalTimeOptionsMenu(!isOpenProposalTimeOptionsMenu);
  };

  const onChangeProposalTimeOption = (option) => {
    setProposalTimesOut(
      proposalTimesOut.map((item) => {
        if (
          item.id === currentProposalTime.id &&
          !item.options.includes(option)
        ) {
          let itemOptions = item.options;
          if (option === PROPOSAL_TIME_OPTION_TYPES["1X"]) {
            itemOptions = itemOptions.filter(
              (item) =>
                item !== PROPOSAL_TIME_OPTION_TYPES["2X"] &&
                item !== PROPOSAL_TIME_OPTION_TYPES["3X"]
            );
          }
          if (option === PROPOSAL_TIME_OPTION_TYPES["2X"]) {
            itemOptions = itemOptions.filter(
              (item) =>
                item !== PROPOSAL_TIME_OPTION_TYPES["1X"] &&
                item !== PROPOSAL_TIME_OPTION_TYPES["3X"]
            );
          }
          if (option === PROPOSAL_TIME_OPTION_TYPES["3X"]) {
            itemOptions = itemOptions.filter(
              (item) =>
                item !== PROPOSAL_TIME_OPTION_TYPES["1X"] &&
                item !== PROPOSAL_TIME_OPTION_TYPES["2X"]
            );
          }

          return {
            id: item.id,
            date_time: item.date_time,
            options: [...itemOptions, option],
          };
        } else if (
          item.id === currentProposalTime.id &&
          item.options.includes(option)
        ) {
          return {
            id: item.id,
            date_time: item.date_time,
            options: item.options.filter((item) => item !== option),
          };
        }
        return item;
      })
    );
    setIsOpenProposalTimeOptionsMenu(false);
  };

  const onSubmitCreateLesson = async () => {
    try {
      if (!currentStudent.id || !currentInstructor.id) {
        setErrorMessage("Please select Student/Instructor");
        return;
      }
      const proposalTimesOutInvalidDate = proposalTimesOut.filter((item) => {
        return isValid(item.date_time);
      });
      if (proposalTimesOut.length > proposalTimesOutInvalidDate.length) {
        setDatePickerErrorMessage("Invalid date, please try again");
        return;
      } else {
        setDatePickerErrorMessage();
      }

      setIsLoading(true);
      const data = {
        lesson: {
          student_id: currentStudent.id,
          instructor_id: currentInstructor.id,
          studio_id: studio_id,
          out: proposalTimesOut.map((item) => {
            return {
              ...item,
              date_time: zonedTimeToUtc(
                item.date_time,
                clientTimeZone
              ).toISOString(),
            };
          }),
        },
      };
      await lessonsApi.create(data);
      setIsLoading(false);
      setIsCreateLessonSuccessFully(true);
    } catch (error) {
      setErrorMessage(error);
      setIsLoading(false);
    }
  };

  const onCloseProposalTimeOptionsMenu = (event) => {
    setProposalTimeOptionsMenuEl(null);
    setIsOpenProposalTimeOptionsMenu(!isOpenProposalTimeOptionsMenu);
  };

  const onSelectAddress = (event) => {
    setAddressId(event.target.value);
  };

  return (
    <StylesProvider generateClassName={generateClassName}>
      <ThemeProvider theme={theme}>
        {isOpen && (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={isOpen}
              onClose={() => {
                onClose();
                setIsOpenInstructorListPopper(false);
              }}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Fade in={isOpen}>
                <ProposeModal>
                  {!isCreateLessonSuccessfully ? (
                    <>
                      <Close onClick={onClose}>
                        <CloseIcon />
                      </Close>
                      <TitleModal>Create lesson</TitleModal>
                      <ProposeInstructorInfo>
                        <ProposeInstructorInfoImg
                          backgroundImage={
                            currentStudent.id &&
                            currentStudent.attributes.avatar_url &&
                            currentStudent.attributes.avatar_url !== ""
                              ? currentStudent.attributes.avatar_url
                              : UserDefaultImage
                          }
                        >
                          <img
                            src={
                              currentStudent.id &&
                              currentStudent.attributes.avatar_url &&
                              currentStudent.attributes.avatar_url !== ""
                                ? currentStudent.attributes.avatar_url
                                : UserDefaultImage
                            }
                            alt=""
                            style={{
                              width: 60,
                              height: 60,
                              borderRadius: "50%",
                              marginRight: 16,
                              display: "none",
                            }}
                          />
                        </ProposeInstructorInfoImg>

                        <ProposeInstructorNameBox>
                          <ProposeUserRole>Student</ProposeUserRole>
                          <ProposeInstructorName
                            onClick={(event) => {
                              onOpenStudentListPopper(event);
                            }}
                            aria-describedby={"student-list-popover"}
                          >
                            {currentStudent.id
                              ? currentStudent.attributes.first_name
                              : "Please select Student"}
                            <ExpandMoreIcon />
                          </ProposeInstructorName>
                        </ProposeInstructorNameBox>
                      </ProposeInstructorInfo>
                      <ProposeInstructorInfo>
                        <ProposeInstructorInfoImg
                          backgroundImage={
                            currentInstructor.id &&
                            currentInstructor.attributes.avatar_url &&
                            currentInstructor.attributes.avatar_url !== ""
                              ? currentInstructor.attributes.avatar_url
                              : UserDefaultImage
                          }
                        >
                          <img
                            src={
                              currentInstructor.id &&
                              currentInstructor.attributes.avatar_url &&
                              currentInstructor.attributes.avatar_url !== ""
                                ? currentInstructor.attributes.avatar_url
                                : UserDefaultImage
                            }
                            alt=""
                            style={{
                              width: 60,
                              height: 60,
                              borderRadius: "50%",
                              marginRight: 16,
                              display: "none",
                            }}
                          />
                        </ProposeInstructorInfoImg>

                        <ProposeInstructorNameBox>
                          <ProposeUserRole>Instructor</ProposeUserRole>
                          <ProposeInstructorName
                            onClick={(event) => {
                              onOpenInstructorListPopper(event);
                            }}
                            aria-describedby={"instructor-list-popover"}
                          >
                            {currentInstructor.id
                              ? currentInstructor.attributes.first_name
                              : "Please select Instructor"}
                            <ExpandMoreIcon />
                          </ProposeInstructorName>
                        </ProposeInstructorNameBox>
                      </ProposeInstructorInfo>

                      {Array.isArray(addresses) && addresses.length > 0 && (
                        <FormControl
                          fullWidth
                          style={{
                            margin: "8px 0",
                          }}
                        >
                          <InputLabel
                            id="address-select-label"
                            style={{ color: "#000000" }}
                          >
                            Location
                          </InputLabel>
                          <Select
                            labelId="address-select-label"
                            value={addressId}
                            onChange={onSelectAddress}
                          >
                            {Array.isArray(addresses) &&
                              addresses.length > 0 &&
                              addresses.map((address) => {
                                const { id, attributes } = address;
                                const { street, city, country } = attributes;
                                return (
                                  <MenuItem value={id} key={`address-${id}`}>
                                    {`${studioName} - ${street}, ${city}, ${country}`}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        </FormControl>
                      )}

                      <FormControl
                        fullWidth
                        style={{
                          margin: "8px 0",
                        }}
                      >
                        <ProposalTimesPrimary>
                          Please select time
                        </ProposalTimesPrimary>
                        <ProposalTimesBox>
                          {proposalTimesOut.map((proposalTime) => (
                            <ProposalTime
                              key={`proposal-time-${proposalTime.id}`}
                            >
                              <ProposalTimeDatePicker>
                                <KeyboardDateTimePicker
                                  value={proposalTime.date_time}
                                  onChange={(event) =>
                                    onChangeProposalDateTime(
                                      event,
                                      proposalTime.id
                                    )
                                  }
                                  format="EE MM/dd hh:mm a"
                                  minutesStep={15}
                                  style={{
                                    width: "100%",
                                  }}
                                  placeholder={"Choose date and time"}
                                />
                              </ProposalTimeDatePicker>
                              {proposalTime.options.length > 0 &&
                                proposalTime.options.sort().map((item) => {
                                  if (
                                    item !== PROPOSAL_TIME_OPTION_TYPES["1X"]
                                  ) {
                                    return (
                                      <ProposalTimeCurrentOption
                                        key={`proposal-time-current-option-${item}`}
                                      >
                                        <ProposalTimeCurrentOptionIconButton>
                                          {item ===
                                          PROPOSAL_TIME_OPTION_TYPES.RECURRING ? (
                                            <CachedIcon />
                                          ) : (
                                            <ProposalTimeOption>
                                              {item}
                                            </ProposalTimeOption>
                                          )}
                                        </ProposalTimeCurrentOptionIconButton>
                                      </ProposalTimeCurrentOption>
                                    );
                                  }
                                })}
                              <ProposalTimeOptions>
                                <MoreIconButton
                                  aria-label={`proposal-time-options-${proposalTime.id}`}
                                  onClick={(event) =>
                                    onOpenProposalTimeOptionsPopper(
                                      event,
                                      proposalTime
                                    )
                                  }
                                  aria-describedby={`proposal-time-options-popover${proposalTime.id}`}
                                >
                                  <MoreVertIcon style={{ color: "#000000" }} />
                                </MoreIconButton>
                              </ProposalTimeOptions>
                            </ProposalTime>
                          ))}
                        </ProposalTimesBox>
                      </FormControl>
                      {errorMessage && (
                        <FormControl
                          fullWidth
                          style={{
                            margin: "8px 0 15px 0",
                          }}
                        >
                          <Typography style={{ color: "#FF0000" }}>
                            Error: {errorMessage}
                          </Typography>
                        </FormControl>
                      )}

                      {datePickerErrorMessage && (
                        <FormControl
                          fullWidth
                          style={{
                            margin: "8px 0 15px 0",
                          }}
                        >
                          <Typography style={{ color: "#FF0000" }}>
                            Error: {datePickerErrorMessage}
                          </Typography>
                        </FormControl>
                      )}

                      <FormControl
                        fullWidth
                        style={{
                          margin: "15px 0 8px 0",
                        }}
                      >
                        <SubmitProposalTimeButton
                          variant="contained"
                          color="primary"
                          onClick={onSubmitCreateLesson}
                        >
                          {!isLoading ? (
                            "Submit"
                          ) : (
                            <CircularProgress
                              disableShrink
                              style={{ color: "#fff" }}
                            />
                          )}
                        </SubmitProposalTimeButton>
                      </FormControl>
                    </>
                  ) : (
                    <>
                      <TitleModal>Create lesson</TitleModal>
                      <Typography>
                        Thanks, your lesson has been successfully created.
                      </Typography>
                      <ImageSignUpSuccess>
                        <img src={signUpSuccessImg} alt="" />
                      </ImageSignUpSuccess>
                      <FormControl fullWidth>
                        <SubmitProposalTimeButton
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            onClose();
                            if (isReload) {
                              window.location.reload();
                            }
                          }}
                        >
                          OK
                        </SubmitProposalTimeButton>
                      </FormControl>
                    </>
                  )}
                </ProposeModal>
              </Fade>
            </Modal>
          </MuiPickersUtilsProvider>
        )}

        {isOpenInstructorListPopper && isOpen && (
          <Popper
            id={"instructor-list-popover"}
            open={isOpenInstructorListPopper && isOpen}
            anchorEl={instructorListPopperEl}
            placement={"bottom-end"}
            transition
            style={{
              zIndex: 1301,
            }}
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <ClickAwayListener onClickAway={onCloseInstructorListPopper}>
                  <ProposeModalInstructorList>
                    <List component="nav" aria-label="main mailbox folders">
                      {instructors.map((instructor) => (
                        <ListItem
                          button
                          key={`instructor_popper_${instructor.id}`}
                          onClick={() => onClickInstructorPopper(instructor)}
                        >
                          <ListItemText
                            primary={instructor.attributes.first_name}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </ProposeModalInstructorList>
                </ClickAwayListener>
              </Fade>
            )}
          </Popper>
        )}

        {isOpenStudentListPopper && isOpen && (
          <Popper
            id={"student-list-popover"}
            open={isOpenStudentListPopper && isOpen}
            anchorEl={studentListPopperEl}
            placement={"bottom-end"}
            transition
            style={{
              zIndex: 1301,
            }}
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <ClickAwayListener onClickAway={onCloseStudentListPopper}>
                  <ProposeModalInstructorList>
                    <List component="nav" aria-label="main mailbox folders">
                      {students.map((student) => (
                        <ListItem
                          button
                          key={`student_popper_${student.id}`}
                          onClick={() => onClickStudentPopper(student)}
                        >
                          <ListItemText
                            primary={student.attributes.first_name}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </ProposeModalInstructorList>
                </ClickAwayListener>
              </Fade>
            )}
          </Popper>
        )}

        {isOpenProposalTimeOptionsMenu && isOpen && (
          <ProposalTimeOptionsMenu
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            id="proposal-time-options-menu"
            keepMounted
            open={isOpenProposalTimeOptionsMenu && isOpen}
            anchorEl={proposalTimeOptionsMenuEl}
            onClose={onCloseProposalTimeOptionsMenu}
            TransitionComponent={Fade}
          >
            <ProposalTimeOptionsBox>
              <ProposalTimeOptionItemRecurring>
                <ProposalTimeOptionIconButton
                  aria-label="proposal-time-option-recurring"
                  onClick={() =>
                    onChangeProposalTimeOption(
                      PROPOSAL_TIME_OPTION_TYPES.RECURRING
                    )
                  }
                  active={(
                    currentProposalTime.id &&
                    currentProposalTime.options.includes(
                      PROPOSAL_TIME_OPTION_TYPES.RECURRING
                    )
                  ).toString()}
                >
                  <CachedIcon />
                </ProposalTimeOptionIconButton>
              </ProposalTimeOptionItemRecurring>
              <ProposalTimeOptionItem>
                <ProposalTimeOptionIconButton
                  aria-label="proposal-time-option-1x"
                  onClick={() =>
                    onChangeProposalTimeOption(PROPOSAL_TIME_OPTION_TYPES["1X"])
                  }
                  active={(
                    currentProposalTime.id &&
                    currentProposalTime.options.includes(
                      PROPOSAL_TIME_OPTION_TYPES["1X"]
                    )
                  ).toString()}
                >
                  <ProposalTimeOption>1X</ProposalTimeOption>
                </ProposalTimeOptionIconButton>
              </ProposalTimeOptionItem>
              <ProposalTimeOptionItem>
                <ProposalTimeOptionIconButton
                  aria-label="proposal-time-option-2x"
                  onClick={() =>
                    onChangeProposalTimeOption(PROPOSAL_TIME_OPTION_TYPES["2X"])
                  }
                  active={(
                    currentProposalTime.id &&
                    currentProposalTime.options.includes(
                      PROPOSAL_TIME_OPTION_TYPES["2X"]
                    )
                  ).toString()}
                >
                  <ProposalTimeOption>2X</ProposalTimeOption>
                </ProposalTimeOptionIconButton>
              </ProposalTimeOptionItem>
              <ProposalTimeOptionItem>
                <ProposalTimeOptionIconButton
                  aria-label="proposal-time-option-3x"
                  onClick={() =>
                    onChangeProposalTimeOption(PROPOSAL_TIME_OPTION_TYPES["3X"])
                  }
                  active={(
                    currentProposalTime.id &&
                    currentProposalTime.options.includes(
                      PROPOSAL_TIME_OPTION_TYPES["3X"]
                    )
                  ).toString()}
                >
                  <ProposalTimeOption>3X</ProposalTimeOption>
                </ProposalTimeOptionIconButton>
              </ProposalTimeOptionItem>
            </ProposalTimeOptionsBox>
          </ProposalTimeOptionsMenu>
        )}
      </ThemeProvider>
    </StylesProvider>
  );
};

export default AddLessonModal;
