import { BASE_URL } from "../utils/constants";
import axiosClient from "./apiClient";

const studiosApi = {
  update: async (params, id) => {
    try {
      const url = `${BASE_URL}/studios/${id}`;
      const response = await axiosClient.put(url, params);
      return response.data;
    } catch (error) {
      throw error.data;
    }
  },
  inviteMember: async (id, params) => {
    try {
      const url = `${BASE_URL}/studios/${id}/invite_member`;
      const response = await axiosClient.post(url, params);
      return response.data;
    } catch (error) {
      throw error.data;
    }
  },
  memberApprove: async (id, params) => {
    try {
      const url = `${BASE_URL}/studios/${id}/member_approve`;
      const response = await axiosClient.post(url, params, {
        headers: {
        }
      });
      return response.data;
    } catch (error) {
      throw error.data;
    }
  },
  memberReject: async (id, params) => {
    try {
      const url = `${BASE_URL}/studios/${id}/member_reject`;
      const response = await axiosClient.post(url, params, {
        headers: {
        }
      });
      return response.data;
    } catch (error) {
      throw error.data;
    }
  },
  getList: async () => {
    try {
      const url = `${BASE_URL}/studios`;
      const response = await axiosClient.get(url);
      return response.data;
    } catch (error) {
      throw error.data;
    }
  },
  memberRequest: async (id) => {
    try {
      const url = `${BASE_URL}/studios/${id}/member_request`;
      const response = await axiosClient.post(url, {}, {
        headers: {
        }
      });
      return response.data;
    } catch (error) {
      throw error.data;
    }
  },
  getDetail: async (id) => {
    try {
      const url = `${BASE_URL}/studios/${id}`;
      const response = await axiosClient.get(url);
      return response;
    } catch (error) {
      throw error.data;
    }
  },
};

export default studiosApi;