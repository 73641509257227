// src/components/SharedSessionCard.js
import React from "react";
import {
  Box,
  withStyles,
  StylesProvider,
  ThemeProvider,
  createGenerateClassName,
} from "@material-ui/core";
import UserDefaultImage from "../../assets/images/user-default.png";

const generateClassName = createGenerateClassName({
  productionPrefix: "c",
  disableGlobal: true,
  seed: "sharedsessioncard",
});

const theme = {
  typography: {
    fontFamily: "'Varela Round', sans-serif",
  },
};

const SharedSessionCardBox = withStyles(() => ({
  root: {
    width: "100%",
    height: 180,
    borderRadius: 12,
    overflow: "hidden",
    boxShadow: "0 3px 6px rgba(0,0,0,0.1)",
    marginBottom: 16,
    position: "relative",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
}))(Box);

const SharedSessionCardTitle = withStyles(() => ({
  root: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    textAlign: "center",
    color: "#fff",
    backgroundColor: "rgba(0, 0, 0, 0.5)", // 50% opacity black
    backdropFilter: "blur(8px)", // Blur effect
    padding: "8px 0",
    fontWeight: 500,
    fontSize: 16,
  },
}))(Box);

const LinkTo = withStyles(() => ({
  root: {
    textDecoration: "none",
    color: "inherit",
  },
}))(Box);

const SharedSessionCard = ({ id, title, avatar_url }) => {
  return (
    <StylesProvider generateClassName={generateClassName}>
      <ThemeProvider theme={theme}>
        <LinkTo component="a" href={`/practice_sessions/${id}`}>
          <SharedSessionCardBox
            style={{
              backgroundImage: `url(${avatar_url ? avatar_url : UserDefaultImage})`,
            }}
          >
            <SharedSessionCardTitle>{title}</SharedSessionCardTitle>
          </SharedSessionCardBox>
        </LinkTo>
      </ThemeProvider>
    </StylesProvider>
  );
};

export default SharedSessionCard;
