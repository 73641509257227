import { BASE_URL } from "../utils/constants";
import axiosClient from "./apiClient";

const lessonsApi = {
  create: async (params) => {
    try {
      const url = `${BASE_URL}/lessons/`;
      const response = await axiosClient.post(url, params);
      return response.data;
    } catch (error) {
      throw error.data;
    }
  },
  update: async (id, params) => {
    try {
      const url = `${BASE_URL}/lessons/${id}`;
      const response = await axiosClient.put(url, params);
      return response.data;
    } catch (error) {
      throw error.data;
    }
  },
  cancelLesson: async (id, params) => {
    try {
      const url = `${BASE_URL}/lessons/${id}/cancel`;
      const response = await axiosClient.post(url, params);
      return response.data;
    } catch (error) {
      throw error.data;
    }
  },
  getList: async (params) => {
    try {
      const url = `${BASE_URL}/lessons`;
      const response = await axiosClient.get(url, { params });
      return response;
    } catch (error) {
      throw error.data;
    }
  },
  uploadVideos: async (id, params) => {
    try {
      const url = `${BASE_URL}/lessons/${id}/upload_videos`;
      const response = await axiosClient.post(url, params);
      return response;
    } catch (error) {
      throw error.data;
    }
  },
  uploadBigVideos: async (id, params) => {
    try {
      const url = `${BASE_URL}/lessons/${id}/upload_big_videos`;
      const response = await axiosClient.post(url, params);
      return response;
    } catch (error) {
      throw error.data;
    }
  },
  deleteVideo: async (id, params) => {
    try {
      const url = `${BASE_URL}/lessons/${id}/delete_video`;
      const response = await axiosClient.post(url, params);
      return response;
    } catch (error) {
      throw error.data;
    }
  },
  getDetail: async (id) => {
    try {
      const url = `${BASE_URL}/lessons/${id}`;
      const response = await axiosClient.get(url);
      return response;
    } catch (error) {
      throw error.data;
    }
  },
  viewCalendar: async (month, year, params) => {
    try {
      const url = `${BASE_URL}/lessons/view_calendar?month=${month}&year=${year}`;
      const response = await axiosClient.get(url, { params });
      return response;
    } catch (error) {
      throw error.data;
    }
  },
};

export default lessonsApi;
