import {
  Link,
  makeStyles,
  withStyles,
  Box,
  createGenerateClassName,
  createTheme,
  StylesProvider,
  ThemeProvider,
  Typography,
  Button,
} from "@material-ui/core";
import React from "react";
import UserDefaultImage from "../../assets/images/user-default.png";

const generateClassName = createGenerateClassName({
  productionPrefix: "c",
  disableGlobal: true,
  seed: "user-card",
});

const theme = createTheme({
  typography: {
    fontFamily: "'Varela Round', sans-serif",
  },
  overrides: {
    MuiFormLabel: {
      root: {
        "&$focused": {
          color: "#333333",
        },
      },

      focused: {
        color: "#333333",
      },
    },
    MuiInput: {
      underline: {
        "&::after": {
          borderBottom: "2px solid #333333",
        },
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  userAvatar: {
    width: "100%",
    display: "block",
    borderTopLeftRadius: "16px",
    borderTopRightRadius: "16px",
  },
}));
const UserCardBox = withStyles(() => ({
  root: {
    borderRadius: 16,
    cursor: "pointer",
    backgroundColor: "#ffffff",
    border: "1px solid #E0E0E0",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.05)",
    paddingBottom: 16,
  },
}))(Box);

const UserName = withStyles(() => ({
  root: {
    fontSize: 16,
    lineHeight: "21px",
    fontWeight: 600,
    textAlign: ({ isstudioowneraction }) =>
      isstudioowneraction === "true" ? "left" : "center",
    padding: ({ isstudioowneraction }) =>
      isstudioowneraction === "true"
        ? "16px 16px 8px 16px"
        : "16px 16px 0 16px",
  },
}))(Typography);

const LinkTo = withStyles(() => ({
  root: {
    color: "#000000",
  },
}))(Link);

const ActionBox = withStyles(() => ({
  root: {
    padding: "6px 16px",
  },
}))(Box);

const RejectButton = withStyles(() => ({
  root: {
    color: "#FF0000",
    backgroundColor: "#ffffff",
    height: 50,
    borderRadius: "140px",
    width: "100%",
    border: "1px solid #FF0000",
    "&:hover": {
      backgroundColor: "#ffffff",
    },
  },
}))(Button);

const ApproveButton = withStyles(() => ({
  root: {
    color: "#ffffff",
    backgroundColor: "#000000",
    height: 50,
    borderRadius: "140px",
    width: "100%",
    border: "1px solid #000000",
    "&:hover": {
      backgroundColor: "#000000",
    },
  },
}))(Button);

const UserCardImg = withStyles((theme) => ({
  root: {
    height: 200,
    backgroundImage: ({ backgroundImage }) => `url(${backgroundImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    boxShadow: "none",
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    [theme.breakpoints.down("sm")]: {
      height: 132,
    },
  },
}))(Box);

const UserCard = ({
  id,
  avatar_url,
  full_name,
  isStudioOwnerAction,
  onReject,
  onApprove,
}) => {
  const classes = useStyles();
  return (
    <StylesProvider generateClassName={generateClassName}>
      <ThemeProvider theme={theme}>
        <UserCardBox>
          <LinkTo href={`/users/${id}`}>
            <UserCardImg
              backgroundImage={
                avatar_url !== "" ? avatar_url : UserDefaultImage
              }
            >
              <img
                src={avatar_url !== "" ? avatar_url : UserDefaultImage}
                alt=""
                className={classes.userAvatar}
                style={{
                  display: "none",
                }}
              />
            </UserCardImg>
          </LinkTo>
          {isStudioOwnerAction ? (
            <>
              <LinkTo href={`/users/${id}`}>
                <UserName
                  isstudioowneraction={(!!isStudioOwnerAction).toString()}
                >
                  {full_name}
                </UserName>
              </LinkTo>
              <ActionBox>
                <RejectButton onClick={onReject}>Reject</RejectButton>
              </ActionBox>
              <ActionBox>
                <ApproveButton onClick={onApprove}>Approve</ApproveButton>
              </ActionBox>
            </>
          ) : (
            <LinkTo href={`/users/${id}`}>
              <UserName>{full_name}</UserName>
            </LinkTo>
          )}
        </UserCardBox>
      </ThemeProvider>
    </StylesProvider>
  );
};

export default UserCard;
