import React, { useState } from "react";
import {
  createGenerateClassName,
  StylesProvider,
  ThemeProvider,
  createTheme,
  Box,
  withStyles,
  makeStyles,
  Fade,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Link,
  Menu,
} from "@material-ui/core";
import { NAV_BARS, USER_ROLES } from "../../utils/constants";
import clsx from "clsx";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import EventNoteOutlined from "@material-ui/icons/EventNoteOutlined";
import DashboardIcon from "@material-ui/icons/Dashboard";
import PersonIcon from "@material-ui/icons/Person";
import ReceiptIcon from "@material-ui/icons/Receipt";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import AddLessonModal from "../Modal/AddLesson";
import usersApi from "../../apis/usersApi";
import ExtensionIcon from "@material-ui/icons/Extension";
import studiosApi from "../../apis/studiosApi";

const generateClassName = createGenerateClassName({
  productionPrefix: "c",
  disableGlobal: true,
  seed: "nav-bar",
});

const theme = createTheme({
  typography: {
    fontFamily: "'Varela Round', sans-serif",
  },
  overrides: {
    MuiFormLabel: {
      root: {
        "&$focused": {
          color: "#333333",
        },
      },

      focused: {
        color: "#333333",
      },
    },
    MuiInput: {
      underline: {
        "&::after": {
          borderBottom: "2px solid #333333",
        },
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  ul: {
    margin: 0,
    padding: 0,
    listStyle: "none",
    display: "flex",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    scrollbarWidth: "none",
    "-ms-overflow-style": "none",
  },
  ulLeft: {
    marginRight: 16,
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
    },
  },
  li: {
    borderRadius: "140px",
    border: "1px solid #000000",
    backgroundColor: "#ffffff",
    marginRight: 16,
    minWidth: "fit-content",
    cursor: "pointer",
    maxHeight:"52px",
    "&:last-child": {
      marginRight: 0,
    },
  },
  liActive: {
    backgroundColor: "#000000",
  },
  a: {
    textDecoration: "none",
    color: "#000000",
    fontSize: 14,
    lineHeight: "18px",
    fontWeight: 500,
    textTransform: "uppercase",
    display: "flex",
    padding: "16px 24px",
  },
  aActive: {
    color: "#ffffff",
  },
  subMenu: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  hideMenu: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  createLessonLi: {
    border: "1px solid #007BFF",
    backgroundColor: "#007BFF",
  },
  createLessonLink: {
    color: "#ffffff",
  },
}));

const NavBarBox = withStyles((theme) => ({
  root: {
    display: "flex",
    marginBottom: theme.spacing(4),
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 16,
    },
  },
}))(Box);

const MyLessonsPopper = withStyles(() => ({
  root: {
    backgroundColor: "#ffffff",
    borderRadius: 16,
    border: "1px solid #E0E0E0",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.05)",
    width: 200,
    position: "relative",
  },
}))(Box);

const MyBusinessPopper = withStyles(() => ({
  root: {
    backgroundColor: "#ffffff",
    borderRadius: 16,
    border: "1px solid #E0E0E0",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.05)",
    width: 230,
    position: "relative",
  },
}))(Box);

const MyLessonsPopperTriangle = withStyles(() => ({
  root: {
    position: "absolute",
    top: "-7px",
    left: 0,
    right: 0,
    width: 12,
    height: 12,
    margin: "auto",
    transform: "rotate(45deg)",
    "-webkit-transform": "rotate(225deg)",
    "-moz-transform": "rotate(45deg)",
    "-o-transform": "rotate(45deg)",
    "-ms-transform": "rotate(45deg)",
    borderRight: "1px solid #E0E0E0",
    borderBottom: "1px solid #E0E0E0",
    backgroundColor: "#ffffff",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.05)",
  },
}))(Box);

const MyLessonsPopperList = withStyles(() => ({
  root: {
    padding: 0,
  },
}))(List);

const MyLessonsPopperListItemIcon = withStyles(() => ({
  root: {
    minWidth: 24,
  },
}))(ListItemIcon);

const SchedulingIcon = withStyles(() => ({
  root: { fontSize: 16, color: "#000000" },
}))(CalendarTodayIcon);

const CompletedICon = withStyles(() => ({
  root: { fontSize: 16, color: "#000000" },
}))(CheckCircleOutlineIcon);

const NoteICon = withStyles(() => ({
  root: { fontSize: 16, color: "#000000" },
}))(EventNoteOutlined);

const TeamIcon = withStyles(() => ({
  root: { fontSize: 16, color: "#000000" },
}))(SupervisorAccountIcon);

const OverViewICon = withStyles(() => ({
  root: { fontSize: 16, color: "#000000" },
}))(DashboardIcon);

const MemberShipIcon = withStyles(() => ({
  root: { fontSize: 16, color: "#000000" },
}))(PersonIcon);

const ProductIcon = withStyles(() => ({
  root: { fontSize: 16, color: "#000000" },
}))(ExtensionIcon);

const TransactionsIcon = withStyles(() => ({
  root: { fontSize: 16, color: "#000000" },
}))(ReceiptIcon);

const MyLessonsPopperListItemText = withStyles(() => ({
  root: { color: "#000000" },
}))(ListItemText);

const NavBar = ({ active }) => {
  const current_user = window.current_user || {};
  const STRIPE_CLIENT_ID = window.STRIPE_CLIENT_ID;
  const { role, manage_studio_id, has_studio_completed_oauth, membership_status = "none" } = current_user;
  const classes = useStyles();
  const [myLessonsMenuEl, setMyLessonsMenuEl] = useState(null);
  const [openMyLessonsMenu, setOpenMyLessonsMenu] = useState(false);
  const [myBusinessMenuEl, setMyBusinessMenuEl] = useState(null);
  const [openMyBusinessMenu, setOpenMyBusinessMenu] = useState(false);
  const [isOpenAddLessonModal, setIsOpenAddLessonModal] = useState(false);
  const [students, setStudents] = useState([]);
  const [instructors, setInstructors] = useState([]);
  const [isFetchedStudentAndInstructor, setIsFetchedStudentAndInstructor] =
    useState(false);
  const [addresses, setAddresses] = useState([]);
  const [studioName, setStudioName] = useState("");

  const onOpenMyLessonsMenu = (event) => {
    setMyLessonsMenuEl(event.currentTarget);
    setOpenMyLessonsMenu(!openMyLessonsMenu);
  };

  const onCloseMyLessonsMenu = (event) => {
    setMyLessonsMenuEl(null);
    setOpenMyLessonsMenu(!openMyLessonsMenu);
  };

  const onOpenMyBusinessMenu = (event) => {
    setMyBusinessMenuEl(event.currentTarget);
    setOpenMyBusinessMenu(!openMyBusinessMenu);
  };

  const onCloseMyBusinessMenu = (event) => {
    setMyBusinessMenuEl(null);
    setOpenMyBusinessMenu(!openMyBusinessMenu);
  };

  const openAddLessonModal = async () => {
    try {
      if (!isFetchedStudentAndInstructor) {
        const studentsRes = await usersApi.getStudents();
        if (Array.isArray(studentsRes) && studentsRes.length > 0) {
          setStudents(studentsRes);
        }
        const instructorsRes = await usersApi.getInstructors();
        if (Array.isArray(instructorsRes) && instructorsRes.length > 0) {
          instructorsRes.unshift({
            id: current_user.id,
            attributes: current_user,
          });
          setInstructors(instructorsRes);
        }

        const studioRes = await studiosApi.getDetail(manage_studio_id);
        if (studioRes.data) {
          const { attributes } = studioRes.data || {};
          setStudioName(attributes.name);
        }
        if (
          Array.isArray(studioRes.included) &&
          studioRes.included.length > 0
        ) {
          const addressesFilter = studioRes.included.filter(
            (e) => e.type === "address"
          );
          setAddresses(addressesFilter);
        }
      }

      setIsFetchedStudentAndInstructor(true);
      setIsOpenAddLessonModal(true);
    } catch (error) {
      setIsFetchedStudentAndInstructor(false);
    }
  };

  const handleCloseSnackBar = () => {
    setShowSnackBar(false);
  };


  return (
    membership_status === "approved" && (
      <StylesProvider generateClassName={generateClassName}>
        <ThemeProvider theme={theme}>
          <NavBarBox>
            <ul className={clsx(classes.ul, classes.ulLeft)}>
              <li
                className={clsx(
                  classes.li,
                  active === NAV_BARS.HOME ? classes.liActive : ""
                )}
              >
                <a
                  href="/"
                  className={clsx(
                    classes.a,
                    active === NAV_BARS.HOME ? classes.aActive : ""
                  )}
                >
                  Home
                </a>
              </li>
              <li
                className={clsx(
                  classes.li,
                  active === NAV_BARS.STUDIO ? classes.liActive : ""
                )}
              >
                <a
                  href="/my_studio"
                  className={clsx(
                    classes.a,
                    active === NAV_BARS.STUDIO ? classes.aActive : ""
                  )}
                >
                  My studio
                </a>
              </li>
              <li
                className={clsx(
                  classes.li,
                  active === NAV_BARS.LESSONS ? classes.liActive : ""
                )}
                onClick={onOpenMyLessonsMenu}
              >
                <a
                  className={clsx(
                    classes.a,
                    active === NAV_BARS.LESSONS ? classes.aActive : ""
                  )}
                >
                  My lessons
                </a>
              </li>
              {role === USER_ROLES.STUDIO && (
                <li
                  className={clsx(
                    classes.li,
                    active === NAV_BARS.BUSINESS ? classes.liActive : ""
                  )}
                  onClick={onOpenMyBusinessMenu}
                >
                  <a
                    className={clsx(
                      classes.a,
                      active === NAV_BARS.BUSINESS ? classes.aActive : ""
                    )}
                  >
                    My Business
                  </a>
                </li>
              )}
              <li
                className={clsx(
                  classes.li,
                  active === NAV_BARS.CALENDAR ? classes.liActive : ""
                )}
              >
                <a
                  href="/lessons/view_calendar"
                  className={clsx(
                    classes.a,
                    active === NAV_BARS.CALENDAR ? classes.aActive : ""
                  )}
                >
                  My calendar
                </a>
              </li>
              {role === USER_ROLES.STUDIO && (
                <li
                  className={clsx(
                    classes.li,
                    active === NAV_BARS.BULLETIN ? classes.liActive : ""
                  )}
                >
                  <a
                    href="/bulletins"
                    className={clsx(
                      classes.a,
                      active === NAV_BARS.BULLETIN ? classes.aActive : ""
                    )}
                  >
                    Bulletins
                  </a>
                </li>
              )}
              {role === USER_ROLES.STUDIO && (
                <>
                  <li
                    className={clsx(
                      classes.li,
                      classes.hideMenu,
                      classes.createLessonLi
                    )}
                    onClick={openAddLessonModal}
                  >
                    <a className={clsx(classes.a, classes.createLessonLink)}>
                      Create lesson
                    </a>
                  </li>
                </>
              )}
            </ul>
            {role === USER_ROLES.STUDIO && 
              <>
                <ul className={clsx(classes.ul, classes.subMenu)}>
                  <li className={clsx(classes.li, classes.createLessonLi)}>
                    <Link
                      onClick={openAddLessonModal}
                      className={clsx(classes.a, classes.createLessonLink)}
                    >
                      Create lesson
                    </Link>
                  </li>
                </ul>
              </>
            }
          </NavBarBox>

          {openMyLessonsMenu && (
            <Menu
              elevation={0}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              id="my-lessons-menu"
              keepMounted
              open={openMyLessonsMenu}
              anchorEl={myLessonsMenuEl}
              onClose={onCloseMyLessonsMenu}
              TransitionComponent={Fade}
            >
              <MyLessonsPopper>
                <MyLessonsPopperList component="nav">
                  <ListItem
                    button
                    onClick={() => {
                      onCloseMyLessonsMenu();
                      window.location.href = `/lessons?status[]=scheduled`;
                    }}
                  >
                    <MyLessonsPopperListItemIcon>
                      <SchedulingIcon />
                    </MyLessonsPopperListItemIcon>
                    <MyLessonsPopperListItemText primary="Scheduling" />
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => {
                      onCloseMyLessonsMenu();
                      window.location.href = `/lessons?status[]=completed`;
                    }}
                  >
                    <MyLessonsPopperListItemIcon>
                      <CompletedICon />
                    </MyLessonsPopperListItemIcon>
                    <MyLessonsPopperListItemText primary="Completed" />
                  </ListItem>

                  <ListItem
                    button
                    onClick={() => {
                      onCloseMyLessonsMenu();
                      window.location.href = `/practice_sessions`;
                    }}
                  >
                    <MyLessonsPopperListItemIcon>
                      <NoteICon />
                    </MyLessonsPopperListItemIcon>
                    <MyLessonsPopperListItemText primary="Practice Sessions" />
                  </ListItem>
                  
                </MyLessonsPopperList>
                <MyLessonsPopperTriangle />
              </MyLessonsPopper>
            </Menu>
          )}

          {openMyBusinessMenu && (
            <Menu
              elevation={0}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              id="my-business-menu"
              keepMounted
              open={openMyBusinessMenu}
              anchorEl={myBusinessMenuEl}
              onClose={onCloseMyBusinessMenu}
              TransitionComponent={Fade}
            >
              <MyBusinessPopper>
                <MyLessonsPopperList component="nav">
                  <ListItem
                    button
                    onClick={() => {
                      onCloseMyBusinessMenu();
                      window.location.href = `/studios/${manage_studio_id}/my_business/overview`;
                    }}
                  >
                    <MyLessonsPopperListItemIcon>
                      <OverViewICon />
                    </MyLessonsPopperListItemIcon>
                    <MyLessonsPopperListItemText primary="Overview" />
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => {
                      onCloseMyBusinessMenu();
                      window.location.href = `/studios/${manage_studio_id}/my_business/manage_member`;
                    }}
                  >
                    <MyLessonsPopperListItemIcon>
                      <MemberShipIcon />
                    </MyLessonsPopperListItemIcon>
                    <MyLessonsPopperListItemText primary="Manage Students" />
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => {
                      onCloseMyBusinessMenu();
                      window.location.href = `/studios/${manage_studio_id}/my_business/manage_team`;
                    }}
                  >
                    <MyLessonsPopperListItemIcon>
                      <TeamIcon />
                    </MyLessonsPopperListItemIcon>
                    <MyLessonsPopperListItemText primary="Manage Team" />
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => {
                      onCloseMyBusinessMenu();
                      window.location.href = `/items`;
                    }}
                  >
                    <MyLessonsPopperListItemIcon>
                      <ProductIcon />
                    </MyLessonsPopperListItemIcon>
                    <MyLessonsPopperListItemText primary="Manage Products" />
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => {
                      onCloseMyBusinessMenu();
                      window.location.href = `/studios/${manage_studio_id}/my_business/transactions`;
                    }}
                  >
                    <MyLessonsPopperListItemIcon>
                      <TransactionsIcon />
                    </MyLessonsPopperListItemIcon>
                    <MyLessonsPopperListItemText primary="Transactions" />
                  </ListItem>
                </MyLessonsPopperList>
                <MyLessonsPopperTriangle />
              </MyBusinessPopper>
            </Menu>
          )}

          {isOpenAddLessonModal && (
            <AddLessonModal
              isOpen={isOpenAddLessonModal}
              students={students}
              instructors={instructors}
              studio_id={manage_studio_id}
              onClose={() => setIsOpenAddLessonModal(false)}
              addresses={addresses}
              studioName={studioName}
            />
          )}
        </ThemeProvider>
      </StylesProvider>
    )
  );
};

export default NavBar;
